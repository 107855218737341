<template>
  <div class="wrapper">
    <base-button
      icon
      type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon
      type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{ tagBtn: isActive }" />
    <div class="main-content" :class="{ DashboardBody: isActive }">
      <SampleNavbar />
      <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
        <b-breadcrumb>
          <b-breadcrumb-item href="#">Website</b-breadcrumb-item>
          <b-breadcrumb-item href="#" active>Banner</b-breadcrumb-item>
        </b-breadcrumb>
      </base-header>
      <b-container fluid class="mt--7">
        <b-row>
          <b-col md="4">
            <b-card type="default" header-classes="bg-transparent">
              <b-form style="text-align: left">
                <b-row>
                  <b-col md="12">
                    <label>Banner Type *</label>
                    <b-form-select
                      v-model="banner.type"
                      :options="bannerType"
                    ></b-form-select>
                  </b-col>
                  <!-- <b-col md="12" class="mt-4">
                                    <label>Banner Name *</label>
                                    <b-input type="text" placeholder="Add Name" v-model="banner.name"></b-input>
                                </b-col> -->
                  <b-col md="12" class="mt-4">
                    <label>Banner Url *</label>
                    <b-input
                      type="text"
                      placeholder="Banner Url"
                      v-model="banner.url"
                    ></b-input>
                  </b-col>
                  <b-col md="12" class="mt-4">
                    <base-button block type="primary" @click="addBanner"
                      >Add Banner</base-button
                    >
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
          <b-col md="8">
            <b-card type="default" header-classes="bg-transparent">
              <!-- <b-row  class="mb-2">
                            <b-col md="8"></b-col>
                            <b-col md="4"><base-button block type="primary">Export to CSV</base-button></b-col>
                        </b-row> -->
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :line-numbers="true"
                :search-options="{
                  enabled: true,
                  placeholder: 'Search this table',
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                }"
              >
                <template slot="table-row" slot-scope="props" style="w">
                  <span v-if="props.column.field == 'before'">
                    <img :src="props.row.banner_image" width="100%" />
                  </span>
                  <span v-if="props.column.field == 'bannerDataType'">
                    <h5>{{ props.row.banner_cetagory }}</h5>
                  </span>
                  <span v-if="props.column.field == 'after'">
                    <base-button
                      icon
                      type="primary"
                      size="sm"
                      @click="bannerEdit(props.row.banner_id)"
                    >
                      <span class="btn-inner--icon"
                        ><i class="far fa-edit"></i
                      ></span>
                    </base-button>
                    <base-button
                      icon
                      type="danger"
                      size="sm"
                      @click="checkedClick(props.row.banner_id)"
                    >
                      <span class="btn-inner--icon">
                        <i
                          class="ni ni-check-bold"
                          v-if="props.row.banner_status"
                        ></i>
                        <i class="ni ni-fat-remove" v-else></i>
                      </span>
                    </base-button>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <Footer />
    </div>
  </div>
</template>
<script>
import Sidebar from "./../../Starter/Sidebar";
import SampleNavbar from "./../../Starter/SampleNavbar";
import Footer from "./../../Starter/SampleFooter";
import { API_URL } from "../../../router/api";
export default {
  name: "webiste-image-upload",
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data() {
    return {
      isActive: false,
      checked: true,
      columns: [
        {
          label: "Banner Url",
          field: "before",
          width: "150px"
        },
        {
          label: "Banner Type",
          field: "bannerDataType"
        },
        {
          label: "Actions",
          field: "after"
        }
      ],
      banner: {
        id: null,
        type: null,
        url: null
      },
      bannerType: [
        { text: "Home Banner", value: 1 },
        { text: "Side Banner", value: 2 },
        { text: "Land Banner", value: 3 },
        { text: "Registration Banner", value: 4 }
      ]
    };
  },
  computed: {
    rows() {
      return this.$store.state.bannerList == null
        ? []
        : this.$store.state.bannerList;
    }
  },
  methods: {
    toggleClass: function(event) {
      this.isActive = !this.isActive;
    },
    addBanner() {
      const self = this;
      console.log(this.banner);
      const api = {
        callback() {
          self.axios
            .post(`${API_URL}admin_banner`, self.banner)
            .then(response => {
              let obj = response.data;
              obj.status === true
                ? self.$store.dispatch("alertSuccess", obj.message)
                : self.$store.dispatch("alertError", obj.message);
              self.$store.dispatch("getBannerShow");
              self.banner = {};
            });
        }
      };
      this.banner.type == null || this.banner.type == ""
        ? self.$store.dispatch("alertError", "Select Banner Type")
        : this.banner.url == null || this.banner.url == ""
        ? self.$store.dispatch("alertError", "Banner Url Empty")
        : api.callback();
    },
    checkedClick(id) {
      this.rows.map(row => {
        if (row.banner_id === id) {
          row.banner_status = !row.banner_status;
          let active = row.banner_status;
          this.axios
            .post(`${API_URL}admin_banner_active`, { id: id, active: active })
            .then(res => {
              let status = res.data.status;
              let message = res.data.message;
              if (status == true) {
                this.$store.dispatch("getBannerShow");
                this.$store.dispatch("alertSuccess", message);
              } else {
                this.$store.dispatch("alertSuccess", message);
              }
            });
        }
        return row;
      });
    },
    bannerEdit(id) {
      let bannerShow =
        this.$store.state.bannerList == null
          ? []
          : this.$store.state.bannerList.find(data => {
              return data.banner_id === id;
            });
      this.banner = {
        id: bannerShow.banner_id,
        type: bannerShow.banner_cetagory,
        url: bannerShow.banner_image
      };
    }
  },
  mounted() {}
};
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
</style>
